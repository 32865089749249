import { DocumentoRepository } from '../../Infrastructure/Repositories/Documento.repository'

export const DocumentoService = {
    UploadFile: (body : any) => {
        return DocumentoRepository.UploadFile(body)
    },
    ListarHojaDocumento: () => {
        return DocumentoRepository.ListarHojaDocumento()
    },
    ListarReglasPorTipo: (body : any) => {
        return DocumentoRepository.ListarReglasPorTipo(body)
    },
    DownloadFile: (body : any) => {
        return DocumentoRepository.DownloadFile(body)
    }
}