import React from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Calendar } from 'primereact/calendar'

interface IEstadoRegla{
    Codigo: string,
    Detalle: string,
    FechaProceso: Date,
    Mensaje: string,
    PKID: number
}

interface IProps {
    OpenModal: boolean,
    CloseModal: any,
    EstadoRegla: IEstadoRegla
}

interface IState {
}

export default class EstadoRegla extends React.Component<IProps, IState>{

    constructor(props: any){
        super(props)
        this.state = {

        }
    }

    RenderFooter(){
        return(
            <div>
                <Button label="Aceptar" icon="pi pi-check" onClick={this.props.CloseModal} autoFocus/>
            </div>
        )
    }

    render(){
        return(
            <Dialog
                header="Detalle de sincronizacion" 
                visible={this.props.OpenModal} 

                footer={this.RenderFooter()} 
                onHide={this.props.CloseModal}>
                <div className="p-grid nested-grid">
                    <div className="p-col-12 p-lg-4">
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Código</label>
                                    <InputText 
                                        value={this.props.EstadoRegla? this.props.EstadoRegla.Codigo:''}
                                        disabled
                                        name="Codigo"
                                        type="text"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-8">
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Fecha Proceso</label>
                                    <Calendar 
                                        value={this.props.EstadoRegla? new Date(this.props.EstadoRegla.FechaProceso):new Date()}
                                        disabled
                                        name="FechaProceso"
                                        showTime showSeconds
                                        dateFormat="dd/mm/yy"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12">
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Mensaje</label>
                                    <InputTextarea 
                                        value={this.props.EstadoRegla ? (this.props.EstadoRegla.Mensaje === ''? 'Falta Sincronización' : this.props.EstadoRegla.Mensaje):'Falta Sincronización'}
                                        disabled
                                        name="Descripcion"
                                        rows={3} 
                                        cols={10}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
}