import React from "react"
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Checkbox } from 'primereact/checkbox'

import { UsuarioService } from '../../Domain/Services/Usuario.service' 

interface IProps{

}

interface IState{
    Modal: boolean
    ListaUsuario: Array<object>
}

export default class Administracion extends React.Component<IProps, IState>{
    
    constructor(Props: any){
        super(Props)
        this.state = {
            Modal: false,
            ListaUsuario: []
        }
    }

    componentDidMount(){
        this.ListarUsuario()
    }

    ListarUsuario(){
        UsuarioService.Listar()
        .then((response: any) => {
            if(response.success){
                this.setState({
                    ListaUsuario: response.data
                })
            }
        })
        .catch((Error) => {
            alert(Error)
        })
    }

    Administrador = (RowData : any) => {
        return (
            <div className="p-field-checkbox">
                <Checkbox 
                    inputId="binary" 
                    checked={RowData.Administrador}
                    disabled/>
            </div>
        )
    }

    Documento = (RowData : any) => {
        return (
            <div className="p-field-checkbox">
                <Checkbox 
                    inputId="binary" 
                    checked={RowData.Documento}
                    disabled/>
            </div>
        )
    }

    render(){
        return(
            <>
                <DataTable 
                    value={this.state.ListaUsuario}
                    //onSelectionChange={e => handleSelectRow(e.value)} 
                    //selectionMode="single"
                    //header={header}
                    className="p-datatable-customers">
                        <Column field="Usuario" header="Usuario" style={{ textAlign:'center' }}></Column>
                        <Column field="Documento" body={this.Documento} header="Documento" style={{ textAlign:'center' }}></Column>
                        <Column field="Administrador" body={this.Administrador} header="Administrador" style={{ textAlign:'center' }}></Column>
                </DataTable>
            </>
        )
    }
}