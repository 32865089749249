import { PreventaRepository } from '../../Infrastructure/Repositories/Preventa.repository'

export const PreventaService = {    
    Get: (body: any) => {
        return PreventaRepository.Get(body);
    },
    List: (body: any) => {
        return PreventaRepository.List(body);
    },
    Export: (body: any) => {
        return PreventaRepository.Export(body);
    }    
}