import { HTTP } from "../Http/Http"

export const DocumentoRepository = {
    UploadFile: async (body : any) => {
        return HTTP.POST('Documento/UploadFile',body,{})
    },
    ListarHojaDocumento: async () => {
        return HTTP.GET('Documento/ListarHojaDocumento')
    },
    ListarReglasPorTipo: async (body : any) => {
        return HTTP.POST('Documento/ListarReglasPorTipo',body,{})
    },
    DownloadFile: async (body : any) => {
        return HTTP.POST('Documento/DownloadFile',body,{})
    }
}