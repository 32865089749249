import { UsuarioRepository } from '../../Infrastructure/Repositories/Usuario.repository'
import { Credencial } from '../Models/CredencialDTO'

export const UsuarioService = {
    Autenticar: (body : Credencial) => {
        return UsuarioRepository.Auntenticar(body)
    },
    Listar: () => {
        return UsuarioRepository.Listar()
    }
}