import { HTTP } from "../Http/Http"

export const PreventaRepository = {        
    Get: async (body : any) => {        
        return HTTP.GET('ConfiguracionCorreo/'+ body.id);
    },
    List: async (body : any) => {
        return HTTP.POST('Preventa/Listar', body, {});
    },
    Export: async (body : any) => {
        return HTTP.POST('Preventa/Exportar',body,{})
    },   
}