import jwt from 'jwt-decode'

const TOKEN_KEY = 'TOKEN-CH3CSXL3S'
const REFRESH_TOKEN_KEY = 'REFRESH-TOKEN-CH3CSXL3S'

export function setToken(access_token: any,refresh_token: any){
    localStorage.setItem(TOKEN_KEY,access_token);
    localStorage.setItem (REFRESH_TOKEN_KEY,refresh_token); 
}

export function getToken(): any{
    return localStorage.getItem(TOKEN_KEY);
}

export function getRefreshToken(){
    return localStorage.getItem(REFRESH_TOKEN_KEY);
}

export function deleteToken(){
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
}

export function getDecode(): any{
    if(getToken())
        return jwt(getToken());
    else
        return null
}

export function getAuthenticado(){
    //if(getToken() && getDecode().Area === 'Desarrollo'){
    if(getToken()){
        return true;
    }else{
        return false;
    }
}

export function getAuthenticadoImp(){
    if(getToken() && getDecode().Area === 'Implementacion'){
        return true;
    }else{
        return false;
    }
}

export function eliminarTodo(){
    localStorage.removeItem(TOKEN_KEY)
    localStorage.removeItem(REFRESH_TOKEN_KEY)    
}