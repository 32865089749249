import React, { useRef, useState } from 'react'
import { Modal } from 'react-responsive-modal'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Chart } from 'primereact/chart';
import { FlexPaginator } from '../Components/FlexPaginator'
import 'react-responsive-modal/styles.css'
import '../Assets/css/modal.css'


export default function ModalConfiguracionCorreo(props) {

    const [disabled, setDisabled] = useState(true);

    const responseGoogle = (response) => {
        console.log(response);
    }

    const logout = (response) => {
        console.log(response);
    }

    const ColoredLine = ({ color }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: 2
            }}
        />
    );

    return (
        <Modal
            open={props.openModal}
            onClose={props.closeModal}
            center>
            <div className="headerModal">
                <h2 style={{
                    color: '#4D80E4',
                    fontFamily: 'Sarala',
                    fontSize: '22px',
                    textAlign: 'center'
                }}>
                </h2>
            </div>
            <div className="modal-body p-mt-3">
                <div className="p-grid nested-grid">
                    <div className="p-col-12">
                        <div className="p-grid p-fluid ">
                            <div className="p-col-10">
                                <InputText
                                    name="correo" placeholder="Cuenta correo"
                                    type="email"
                                    value={props.entidad.correo}
                                    onChange={props.handleChange} />
                            </div>
                            <div className="p-col-2">
                            </div>
                            <div className="p-col-6">
                                <InputText
                                    name="clientId" placeholder="clientId"
                                    type="text"
                                    value={props.entidad ? props.entidad.clientId : ''}
                                    onChange={props.handleChange} />
                            </div>
                            <div className="p-col-6">
                                <InputText
                                    name="clientSecret" placeholder="clientSecret"
                                    type="text"
                                    value={props.entidad ? props.entidad.clientSecret : ''}
                                    onChange={props.handleChange} />
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12">
                        <ColoredLine color="blue" />
                    </div>
                    <div className="p-col-12">
                        <h3>Datos de prueba (Habilitado sólo si la configuración fue guardada previamente)</h3>
                        <div className="p-grid p-fluid ">
                            <div className="p-col-10">
                                <InputText
                                    name="correoDestino" placeholder="Correo destino"
                                    type="email"
                                    value={props.entidad.correoDestino}
                                    onChange={props.handleChange} />
                            </div>
                            <div className="p-col-2">
                            </div>
                            <div className="p-col-6">
                                <InputText
                                    name="asunto" placeholder="Asunto"
                                    type="text"
                                    value={props.entidad.asunto}
                                    onChange={props.handleChange} />
                            </div>
                            <div className="p-col-6">
                                <InputTextarea
                                    name="mensaje" placeholder="Mensaje"
                                    type="text"
                                    value={props.entidad.mensaje}
                                    onChange={props.handleChange} />
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12">
                        <ColoredLine color="blue" />
                    </div>
                    <div className="p-col-7">

                    </div>
                    <div className="p-col-5">
                        <div className="p-col-12">

                        </div>

                        <div className="p-col-12">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-4">
                                    <Button
                                        type="button"
                                        label="Guardar"
                                        style={{
                                            background: '#4D80E4',
                                            border: '1px solid #4D80E4',
                                            alignContent: 'end'
                                        }}
                                        onClick={(e) => props.onActualizar(e, 1)}
                                        autoFocus />
                                </div>
                                <div className="p-col-4">
                                    <Button disabled={props.entidad.id == 0}
                                        type="button"
                                        label="Habilitar correo"
                                        style={{
                                            background: '#4D80E4',
                                            border: '1px solid #4D80E4',
                                            alignContent: 'end'
                                        }}
                                        onClick={(e) => props.onActualizar(e, 2)}
                                        autoFocus />
                                </div>   
                                <div className="p-col-4">
                                    <Button disabled={props.entidad.id == 0}
                                        type="button"
                                        label="Enviar correo prueba"
                                        style={{
                                            background: '#4D80E4',
                                            border: '1px solid #4D80E4',
                                            alignContent: 'end'
                                        }}
                                        onClick={(e) => props.onActualizar(e, 3)}
                                        autoFocus />
                                </div>                                
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Modal>
    )
}