import React, {Component} from 'react'
import './../Assets/css/backdrop.css'

class BackDrop extends Component {
    render() {
        return (
            <div 
                className="backdrop"
                onClick={this.props.click}
            >
            </div>
        );
    }
}

export default BackDrop;