import { ConfiguracionCorreoRepository } from '../../Infrastructure/Repositories/ConfiguracionCorreo.repository'

export const ConfiguracionCorreoService = {    
    Get: (body: any) => {
        return ConfiguracionCorreoRepository.Get(body);
    },
    List: () => {
        return ConfiguracionCorreoRepository.List();
    },
    Save: (body: any) => {
        return ConfiguracionCorreoRepository.Save(body);
    },
    Habilitar: (body: any) => {
        return ConfiguracionCorreoRepository.Habilitar(body);
    },
    EnviarCorreo: (body: any) => {
        return ConfiguracionCorreoRepository.EnviarCorreo(body);
    }
}