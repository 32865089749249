import { HTTP } from "../Http/Http"

export const ConfiguracionCorreoRepository = {
    UploadFile: async (body : any) => {
        return HTTP.POST('Documento/UploadFile',body,{})
    },
    ListarHojaDocumento: async () => {
        return HTTP.GET('Documento/ListarHojaDocumento')
    },
    ListarReglasPorTipo: async (body : any) => {
        return HTTP.POST('Documento/ListarReglasPorTipo',body,{})
    },
    Get: async (body : any) => {
        console.log('Repo');
        console.log(body);
        return HTTP.GET('ConfiguracionCorreo/'+ body.id);
    },
    List: async () => {
        return HTTP.GET('ConfiguracionCorreo');
    },
    Save: async (body : any) => {
        return HTTP.POST('ConfiguracionCorreo',body,{});
    },
    Habilitar: async (body : any) => {
        return HTTP.POST('ConfiguracionCorreo/Habilitar',body,{});
    },
    EnviarCorreo: async (body : any) => {
        return HTTP.POST('ConfiguracionCorreo/EnviarCorreo',body,{});
    },   
}