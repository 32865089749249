import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import "primeflex/primeflex.css"

ReactDOM.render(
    <App />
  ,
  document.getElementById('root')
);

serviceWorker.unregister();
