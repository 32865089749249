import React from 'react'
import jwt from 'jwt-decode'
import Loading from '../Components/Loading'
import PreventaDataTable from '../Components/PreventaDataTable'
import Paginator from '../Components/FlexPaginator'
import EstadoRegla from '../Components/EstadoRegla'
import { TabView, TabPanel } from 'primereact/tabview';
import { ConfiguracionCorreoService } from '../../Domain/Services/ConfiguracionCorreo.service'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import ModalConfiguracionCorreo from './ModalConfiguracionCorreo'
import Swal from 'sweetalert2'
import { getToken } from '../Util/LocalStorageManage'
import { PreventaService } from '../../Domain/Services/Preventa.service'
import FlexPaginator from '../Components/FlexPaginator'

interface IEstadoRegla {
    Codigo: string,
    Detalle: string,
    FechaProceso: Date,
    Mensaje: string,
    PKID: number
}

interface ISniper {
    code: number,
    name: string
}

interface IRegistrosPagina {
    RegistrosxPagina: number
}

interface IPaginado {
    NumeroPagina: number,
    RegistrosxPagina: number,
    NumeroPaginas: number,
    filtro_Anulado: number,
    filtro_DA: '',
    filtro_Marca: '',
    filtro_Vendedor: '',
    filtro_Cliente: '',
    filtro_Canal: '',
    filtro_SubCanal: '',
    filtro_SKU: '',
    filtro_FechaEmision: ''
}

interface ITipoRegla {
    PKID: number,
    Nombre: string
}

interface IProps {
}

interface IState {
    IsLoading: boolean,
    TiposRegla: Array<ISniper>,
    TipoRegla: ITipoRegla,
    ActiveIndex: number,
    Paginado: IPaginado
    Preventas: Array<object>,
    RegistrosPagina: IRegistrosPagina,
    SelectedEstado: any,    
    EstadoRegla: IEstadoRegla,
    OpenModal: boolean,
    Filtro: any,
    entidades: Array<object>
}

export default class Preventa extends React.Component<IProps, IState> {

    constructor(props: any) {
        super(props)
        this.state = {
            IsLoading: true,
            TiposRegla: [],
            TipoRegla: {
                PKID: 0,
                Nombre: ''
            },
            ActiveIndex: 0,
            Paginado: {
                NumeroPagina: 1,
                RegistrosxPagina: 50,
                NumeroPaginas: 0,
                filtro_Anulado: 2,
                filtro_DA: '',
                filtro_Marca: '',
                filtro_Vendedor: '',
                filtro_Cliente: '',
                filtro_Canal: '',
                filtro_SubCanal: '',
                filtro_SKU: '',
                filtro_FechaEmision: ''
            },
            Preventas: [],
            RegistrosPagina: {
                RegistrosxPagina: 50
            },
            SelectedEstado: '',            
            EstadoRegla: {
                Codigo: '',
                Detalle: '',
                FechaProceso: new Date(),
                Mensaje: '',
                PKID: 0
            },
            OpenModal: false,
            Filtro: {
                FechaInicio: new Date(),
                FechaFin: new Date()
            },
            entidades: []
        }
    }

    componentDidMount() {        
        this.setState({
            IsLoading: false
        });
        this.ListarEntidades();
    }

    AdministrarError(error: any) {
        var code = "0000";
        var message = "Error de comunicación.";
        if (error && error.data) {
            code = error.data.error.code;
            message = error.data.error.message;
        }

        Swal.fire({
            title: 'Error: ' + code,
            text: message,
            icon: 'error',
            confirmButtonText: 'OK'
        });
    }

    ListarEntidades() {
        this.setState({
            IsLoading: true
        }, () => {
            PreventaService.List({ HojaDocumento: this.state.TipoRegla, Paginado: this.state.Paginado })
                .then((data: any) => {
                    console.log(data.data.Paginado.filtro_FechaEmision);
                    if (data.success) {
                        //data.data.Paginado.filtro_FechaEmision=new Date(data.data.Paginado.filtro_FechaEmision);
                        this.setState({
                            Preventas: data.data.Preventa,
                            Paginado: data.data.Paginado
                        })
                    } else {
                        alert(data.data);
                    }
                })
                .catch((error) => {
                    this.AdministrarError(error);
                })
                .finally(() => {
                    this.setState({
                        IsLoading: false
                    })
                })
        })
    }

    HandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (e.target.name) {
            default:
                this.setState({
                    Filtro: {
                        ...this.state.Filtro,
                        [e.target.name]: e.target.value
                    }
                });                
                break;
        }
    }

    onActualizar = (e: any, tipoActualizacion: number) => {
        e.preventDefault();

        if (tipoActualizacion == 1) {
            this.setState({
                IsLoading: true
            }, () => {
                ConfiguracionCorreoService.Save({})
                    .then((data: any) => {

                    })
                    .catch((error) => {
                        this.AdministrarError(error);
                    })
                    .finally(() => {
                        this.setState({
                            IsLoading: false
                        });
                    })
            });
        } else if (tipoActualizacion == 2) {
            this.setState({
                IsLoading: true
            }, () => {

            });
        }
        else if (tipoActualizacion == 3) {
            this.setState({
                IsLoading: true
            }, () => {
                ConfiguracionCorreoService.EnviarCorreo({})
                    .then((data: any) => {

                    })
                    .catch((error) => {
                        this.AdministrarError(error);
                    })
                    .finally(() => {
                        this.setState({
                            IsLoading: false
                        })
                    })
            });
        }
    }

    MyPopUpWindow(url: string, width: number, height: number) {
        var leftPosition, topPosition;
        //Allow for borders.
        leftPosition = (window.screen.width / 2) - ((width / 2) + 10);
        //Allow for title and status bars.
        topPosition = (window.screen.height / 2) - ((height / 2) + 50);
        //Open the window.
        var resultado = window.open(url, "_blank",
            "status=no,height=" + height + ",width=" + width + ",resizable=yes,left="
            + leftPosition + ",top=" + topPosition + ",screenX=" + leftPosition + ",screenY="
            + topPosition + ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no");

        return resultado;
    }

    Filter = (Event: any) => {
        this.setState({
            Paginado: {
                ...this.state.Paginado,
                NumeroPagina: 1,
                [Event.target.name]: Event.target.value
            }
        })
    }

    FilterKeyDown = (Event: any) => {
        console.log(Event);
        if (Event.key === 'Enter') {
            this.ListarEntidades();
        }
    }

    HandleSearch = (Event: any) => {
        var Token: string | null = getToken()
        if (Token) {
            this.setState({
                IsLoading: true
            }, () => {
                PreventaService.Export(this.state.Filtro)
                    .then((data: any) => {
                        if (!data.success) {
                            alert(data.data);
                        } else {
                            this.setState({
                                IsLoading: true
                            }, () => this.ListarEntidades());

                            data = JSON.parse(data.data);
                            const byteCharacters = window.atob(data.Archivo);
                            //var buffer = Buffer.from(data.Archivo,'base64');
                            //const byteCharacters = buffer.toString('base64');

                            const byteNumbers = new Array(byteCharacters.length)
                            for (let i = 0; i < byteCharacters.length; i++) {
                                byteNumbers[i] = byteCharacters.charCodeAt(i)
                            }
                            const byteArray = new Uint8Array(byteNumbers);
                            const url = window.URL.createObjectURL(new Blob([byteArray]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', data.NombreArchivo);
                            document.body.appendChild(link);
                            link.click();
                        }
                    })
                    .catch((error) => {
                        this.AdministrarError(error);
                    })
                    .finally(() => {
                        this.setState({
                            IsLoading: false
                        })
                    })
            })
        }
    }

    ChangeTab = (e: { index: number }) => {
        this.setState({
            ActiveIndex: e.index,
            Preventas: [],
            TipoRegla: {
                PKID: this.state.TiposRegla[e.index].code,
                Nombre: this.state.TiposRegla[e.index].name
            },
            Paginado: {
                NumeroPagina: 1,
                RegistrosxPagina: 50,
                NumeroPaginas: 0,
                filtro_Anulado: 2,
                filtro_DA: '',
                filtro_Marca: '',
                filtro_Vendedor: '',
                filtro_Cliente: '',
                filtro_Canal: '',
                filtro_SubCanal: '',
                filtro_SKU: '',
                filtro_FechaEmision: ''
            },
            RegistrosPagina: {
                RegistrosxPagina: 50
            }
        }, () => this.ListarEntidades())
    };

    HandlePreviousLL = () => {
        this.setState({
            Paginado: {
                ...this.state.Paginado,
                NumeroPagina: 1,
                RegistrosxPagina: this.state.Paginado.RegistrosxPagina,
                NumeroPaginas: 0
            }
        }, () => this.ListarEntidades())
    }

    HandlePrevious = () => {
        this.setState({
            Paginado: {
                ...this.state.Paginado,
                NumeroPagina: this.state.Paginado.NumeroPagina - 1
            }
        }, () => this.ListarEntidades())
    }

    HandleFollowing = () => {
        this.setState({
            Paginado: {
                ...this.state.Paginado,
                NumeroPagina: this.state.Paginado.NumeroPagina + 1
            }
        }, () => this.ListarEntidades())
    }

    HandleFollowingLL = () => {
        this.setState({
            Paginado: {
                ...this.state.Paginado,
                NumeroPagina: this.state.Paginado.NumeroPaginas
            }
        }, () => this.ListarEntidades())
    }

    HandleRegistroChange = (e: any) => {
        console.log('HandleRegistroChange');
        console.log(e.value);
        this.setState({
            Paginado: {
                ...this.state.Paginado,
                NumeroPagina: 1,
                RegistrosxPagina: e.value.RegistrosxPagina,
            },
            RegistrosPagina: e.value
        }, () => this.ListarEntidades())
    }

    OnChangeEstado = (e: any) => {
        var cPaginado;
        console.log(e);
        if (e.value === 'Activo') {
            cPaginado = {
                ...this.state.Paginado,
                NumeroPagina: 1,
                filtro_Anulado: 0
            }
        } else if (e.value === 'Anulado') {
            cPaginado = {
                ...this.state.Paginado,
                NumeroPagina: 1,
                filtro_Anulado: 1
            }
        } else {
            cPaginado = {
                ...this.state.Paginado,
                NumeroPagina: 1,
                filtro_Anulado: 2
            }
        }
        this.setState({
            Paginado: cPaginado,
            SelectedEstado: e.value
        }, () => this.ListarEntidades())
    }

    HandleSelectRow = (e: any) => {
        return;        
    }

    header = (
        <div className="p-d-flex p-jc-between">
            <h4>Preventa</h4>
            <Button
                label="Exportar"
                onClick={() => this.setState({ OpenModal: true })}
                autoFocus />
        </div>
    );

    render() {
        return (
            <>
                {this.state.IsLoading ?
                    <Loading Load={this.state.IsLoading} />
                    :
                    <div>
                        <PreventaDataTable                            
                            Preventa={this.state.Preventas}
                            SelectedEstado={this.state.SelectedEstado}                            
                            OnChangeEstado={this.OnChangeEstado}
                            OnChangeEstadoDesactivacion={{}}
                            Loading={this.state.IsLoading}
                            HandleSelectRow={this.HandleSelectRow}
                            Filtro={this.state.Filtro}
                            HandleFilter={this.HandleSearch}
                            HandleChange={this.HandleChange}
                            Filter={this.Filter}
                            FilterKeyDown={this.FilterKeyDown}
                            Paginado={this.state.Paginado} >
                        </PreventaDataTable>

                        <FlexPaginator
                            Paginado={this.state.Paginado}
                            HandlePreviousLL={this.HandlePreviousLL}
                            HandlePrevious={this.HandlePrevious}
                            HandleFollowing={this.HandleFollowing}
                            HandleFollowingLL={this.HandleFollowingLL}
                            RegistrosPagina={this.state.RegistrosPagina}
                            OnRegistroChange={this.HandleRegistroChange} />

                        <ModalConfiguracionCorreo
                            entidad={{}}
                            openModal={this.state.OpenModal}
                            handleChange={this.HandleChange}
                            onActualizar={this.onActualizar}
                            closeModal={() => {
                                this.setState({ OpenModal: false });
                            }}
                        />
                    </div>
                }
            </>
        )
    }
}