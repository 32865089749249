import React, { RefObject } from 'react'
import jwt from 'jwt-decode'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { FileUpload } from 'primereact/fileupload'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'

import '../Assets/css/DataTable.css'
import { InputText } from 'primereact/inputtext'
import { getToken } from '../Util/LocalStorageManage'

interface IProps {
    //HandleFileUpload: any,
    //TipoRegla: ({ code: number, name: string }),
    Preventa: Array<object>,
    SelectedEstado: any,    
    OnChangeEstado: any,
    OnChangeEstadoDesactivacion: any,
    Loading: boolean,
    HandleSelectRow: any,
    HandleFilter: any,
    Filtro: ({
        FechaInicio: Date,
        FechaFin: Date,
    }),
    Paginado: any,
    Filter: any,
    FilterKeyDown: any,
    HandleChange: any,
}

interface IState {
    RefFile: RefObject<FileUpload>,
    Estados: Array<string>,    
    ExpandedRows: Array<object>
}

export default class PreventaDataTable extends React.Component<IProps, IState> {

    constructor(props: any) {
        super(props)
        this.state = {
            RefFile: React.createRef<FileUpload>(),
            Estados: [
                'Activo',
                'Anulado'
            ],
            ExpandedRows: []
        }
    }

    Header() {
        var Token: string | null = getToken()
        if (Token) {            
            var Decoded: any = jwt(Token)
            return (
                <div className="p-d-flex">
                    <div className="p-ml-auto">
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-4">
                                </div>
                                <div className="p-col-4">
                                    <label
                                        className="p-d-block"
                                        style={{
                                            fontFamily: 'Sarala',
                                            fontSize: '15px'
                                        }}
                                    >Fecha</label>
                                    <Calendar
                                        value={this.props.Filtro.FechaFin ? new Date(this.props.Filtro.FechaFin) : new Date()}
                                        name="FechaFin"
                                        onChange={this.props.HandleChange}
                                        className='p-column-filter-xxl'                                        
                                        dateFormat="dd-mm-yy" />
                                </div>
                                <div className="p-col-4 p-as-center p-mt-3">
                                    <Button
                                        label="Descargar" autoFocus onClick={(event) => this.props.HandleFilter(event)} />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            )
        }
    }


    BodyEstado(rowData: any) {
        return (
            <React.Fragment>
                {
                    rowData.Anulado ?
                        <span style={{ color: '#FF5252', fontWeight: 700 }}>Anulado</span>
                        :                        
                        <span style={{ color: '#66BB6A', fontWeight: 700 }}>Activo</span>
                         
                }
            </React.Fragment>
        )
    }

    TemplateFilterEstado(option: any) {
        if (option === 'Activo')
            return <span style={{ color: '#66BB6A', fontWeight: 700 }}>Activo</span>        
        else if (option === 'Anulado')
            return <span style={{ color: '#FF5252', fontWeight: 700 }}>Anulado</span>
        else
            return <span style={{ color: '#FF5252', fontWeight: 700 }}>Anulado</span>
    }

    FilterEstado() {
        return (
            <Dropdown
                value={this.props.SelectedEstado}
                options={this.state.Estados}
                onChange={(e) => this.props.OnChangeEstado(e)}
                itemTemplate={this.TemplateFilterEstado}
                placeholder="Select"
                showClear
                className="p-column-filter-lg" />
        )
    }

    FilterFechaEmision() {
        return (
            <div className="p-ml-auto">
                <div className="p-col-12">
                    <Calendar
                        value={this.props.Paginado.filtro_FechaEmision ? new Date(this.props.Paginado.filtro_FechaEmision) : undefined }
                        name="filtro_FechaEmision"
                        onChange={(Event) => this.props.Filter(Event)}
                        className='p-column-filter'
                        //showTime showSeconds                        
                        dateFormat="dd-mm-yy" />
                    <Button icon="pi pi-search" onClick={(Event) => this.props.FilterKeyDown({key: 'Enter'})} aria-label="Search" />
                </div>
            </div>
        )
    }

    FilterCliente() {
        return (
            <InputText
                className="p-column-filter-xl"
                name="filtro_Cliente"
                value={this.props.Paginado.filtro_Cliente}
                onChange={(Event) => this.props.Filter(Event)}
                onKeyDown={(Event) => this.props.FilterKeyDown(Event)}
            />
        )
    }

    FilterDA() {
        return (
            <InputText
                className="p-column-filter-xl"
                name="filtro_DA"
                value={this.props.Paginado.filtro_DA}
                onChange={(Event) => this.props.Filter(Event)}
                onKeyDown={(Event) => this.props.FilterKeyDown(Event)}
            />
        )
    }

    FilterMarca() {
        return (
            <InputText
                className="p-column-filter"
                name="filtro_Marca"
                value={this.props.Paginado.filtro_Marca}
                onChange={(Event) => this.props.Filter(Event)}
                onKeyDown={(Event) => this.props.FilterKeyDown(Event)}
            />
        )
    }

    FilterVendedor() {
        return (
            <InputText
                className="p-column-filter-xl"
                name="filtro_Vendedor"
                value={this.props.Paginado.filtro_Vendedor}
                onChange={(Event) => this.props.Filter(Event)}
                onKeyDown={(Event) => this.props.FilterKeyDown(Event)}
            />
        )
    }

    FilterSKU() {
        return (
            <InputText
                className="p-column-filter-lg"
                name="filtro_SKU"
                value={this.props.Paginado.filtro_SKU}
                onChange={(Event) => this.props.Filter(Event)}
                onKeyDown={(Event) => this.props.FilterKeyDown(Event)}
            />
        )
    }

    FilterCanal() {
        return (
            <InputText
                className="p-column-filter-lg"
                name="filtro_Canal"
                value={this.props.Paginado.filtro_Canal}
                onChange={(Event) => this.props.Filter(Event)}
                onKeyDown={(Event) => this.props.FilterKeyDown(Event)}
            />
        )
    }

    FilterSubCanal() {
        return (
            <InputText
                className="p-column-filter-lg"
                name="filtro_SubCanal"
                value={this.props.Paginado.filtro_SubCanal}
                onChange={(Event) => this.props.Filter(Event)}
                onKeyDown={(Event) => this.props.FilterKeyDown(Event)}
            />
        )
    }

    RowExpansionTemplate = (Data: any) => {
        //console.log(Data)
        switch (Data.IDTipoRegla) {
            case 1:
                return (
                    <div className="orders-subtable">
                        <DataTable value={Data.Bonificacion}>
                            <Column field="SKUBonificar" header="SKUBonificar" />
                            <Column field="CantidadBonificar" header="CantidadBonificar" />
                            <Column field="FactorUnidadBonificar" header="FactorUnidadBonificar" />
                            <Column field="MinCompra" header="MinCompra" />
                            <Column field="MaxCompra" header="MaxCompra" />
                        </DataTable>
                    </div>
                )
            case 2:
                return (
                    <div className="orders-subtable">
                        <DataTable value={Data.Bonificacion}>
                            <Column field="SKUBonificar" header="SKUBonificar" />
                            <Column field="CantidadBonificar" header="CantidadBonificar" />
                            <Column field="FactorUnidadBonificar" header="FactorUnidadBonificar" />
                            <Column field="MinCompra" header="MinCompra" />
                            <Column field="MaxCompra" header="MaxCompra" />
                        </DataTable>
                    </div>
                )
            case 3:
            case 4:
                return (
                    <div className="orders-subtable">
                        <DataTable value={Data.Descuento}>
                            <Column field="Dsct" header="Descuento" />
                            <Column field="MinCompra" header="Minimo" />
                            <Column field="MaxCompra" header="Maximo" />
                        </DataTable>
                    </div>
                )
            case 5:
                return (
                    <div className="orders-subtable">
                        <DataTable value={Data.DescuentoCiclosCortos}>
                            <Column field="DsctIGC" header="Descuento" />
                            <Column field="MaxPedido" header="M. Pedido" />
                            <Column field="MaxCompraPeriodo" header="M. Periodo" />
                            <Column field="Cantidad" header="Cantidades" />
                            <Column field="Agrupacion1SKUS" header="Producto 1" />
                            <Column field="FactorAgrupacion1SKUS" header="Factor" />
                            <Column field="Agrupacion2SKUS" header="Producto 2" />
                            <Column field="FactorAgrupacion2SKUS" header="Factor" />
                            <Column field="Agrupacion3SKUS" header="Producto 3" />
                            <Column field="FactorAgrupacion3SKUS" header="Factor" />
                            <Column field="Agrupacion4SKUS" header="Producto 4" />
                            <Column field="FactorAgrupacion4SKUS" header="Factor" />
                            <Column field="Agrupacion5SKUS" header="Producto 5" />
                            <Column field="FactorAgrupacion5SKUS" header="Factor" />
                        </DataTable>
                    </div>
                )
        }
    }

    BodyNroDocumento(RowData: any) {
        return (
            <React.Fragment>
                {RowData.NroDocumento.length > 25 ?
                    RowData.NroDocumento.substring(0, 21).replaceAll(';', '; ') + '...'
                    :
                    RowData.NroDocumento.replaceAll(';', '; ')

                }
            </React.Fragment>

        )
    }

    BodyCanal(RowData: any) {
        return (
            <React.Fragment>{RowData.Canal.length > 12 ?
                RowData.Canal.substring(0, 8).replaceAll(';', '; ') + '...'
                :
                RowData.Canal.replaceAll(';', '; ')
            }
            </React.Fragment>
        )
    }

    BodyVendedor(RowData: any) {
        return (
            <React.Fragment>{RowData.Vendedor.replaceAll(';', '; ')}</React.Fragment>
        )
    }

    BodySubCanal(RowData: any) {
        return (
            <React.Fragment>{RowData.SubCanal.replaceAll(';', '; ')}</React.Fragment>
        )
    }

    BodyFechaEmision(RowData: any) {
        return (
            <React.Fragment>{RowData.FechaEmision.replaceAll(';', '; ')}</React.Fragment>
        )
    }

    render() {
        return (
            <div className="datatable-filter-demo">
                <DataTable
                    value={this.props.Preventa}
                    selectionMode="single"
                    header={this.Header()}
                    expandedRows={this.state.ExpandedRows}
                    onRowToggle={(e: any) => this.setState({ ExpandedRows: e.data })}
                    rowExpansionTemplate={this.RowExpansionTemplate}
                    className="p-datatable-customers"
                    loading={this.props.Loading}
                    onSelectionChange={e => this.props.HandleSelectRow(e.value.EstadoRegla)}
                    emptyMessage="No se han encontrado registros.">
                    {/* <Column expander style={{ width: '1%', textAlign: 'center' }} /> */}
                    <Column field="DA" header="DA" filter filterElement={this.FilterDA()} style={{ width: '10%', textAlign: 'center' }} />
                    <Column field="Marca" header="Marca" filter filterElement={this.FilterMarca()} style={{ width: '10%', textAlign: 'center' }} />
                    <Column field="Vendedor" header="Vendedor" filter filterElement={this.FilterVendedor()} style={{ width: '10%', textAlign: 'center' }} />
                    <Column field="Cliente" header="Cliente" filter filterElement={this.FilterCliente()} style={{ width: '10%', textAlign: 'center' }} />
                    <Column field="Canal" header="Canal" filter filterElement={this.FilterCanal()} body={this.BodyCanal} style={{ width: '10%', textAlign: 'center' }} />
                    <Column field="SubCanal" header="SubCanal" filter filterElement={this.FilterSubCanal()} body={this.BodySubCanal} style={{ width: '10%', textAlign: 'center' }} />
                    <Column field="SKU" header="SKU" filter filterElement={this.FilterSKU()} style={{ width: '10%', textAlign: 'center' }} />
                    <Column field="FechaEmision" header="FechaEmision" filter filterElement={this.FilterFechaEmision()} body={this.BodyFechaEmision} style={{ width: '10%', textAlign: 'center' }} />
                    <Column field="Anulado" header="Estado" body={this.BodyEstado} filter filterElement={this.FilterEstado()} style={{ width: '10%', textAlign: 'center' }} />
                </DataTable>
            </div>
        )
    }
}