import React, { Component } from 'react'
import './../Assets/css/slidedrawer.css'

class SlideDrawer extends Component {

    render() {
        return (
            <nav className="slide-drawer">
                <ul>
                    <li className="Children"><a href="/Preventa">Preventa</a></li>                    
                    {/* <li className="SubMenu">
                        <a className="aSubMenu">Seguridad</a>
                        <ul>
                            <li className="Children"><a href="/Seguridad">Usuario</a></li>                            
                        </ul>
                    </li> */}
                    <li className="Children"><a href="/">Salir</a></li>
                </ul>
            </nav>
        );
    }
}

export default SlideDrawer;